/*
 * Copyright (C) 2022 - present Instructure, Inc.
 *
 * This file is part of Canvas.
 *
 * Canvas is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, version 3 of the License.
 *
 * Canvas is distributed in the hope that it will be useful, but WITHOUT ANY
 * WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 * A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License along
 * with this program. If not, see <http://www.gnu.org/licenses/>.
 */

import {useScope as useI18nScope} from '@canvas/i18n'
import $ from 'jquery'
import htmlEscape from 'html-escape'

const I18n = useI18nScope('plugins.suspended_account_warning')

const dummy = document.createElement('div')
document.body.appendChild(dummy)
dummy.innerHTML = `\
<style>
  #suspendedwarning {
    position: fixed;
    z-index: 100000;
    bottom: 0;
    top: auto;
    left: 0;
    background-color: #FDD;
    border-width: 2px 0 0 0;
    border-style: solid;
    border-color: black;
    width: 100%;
    padding: 15px;
    opacity: 0.90;
    color: black;
  }
</style>
<div id="suspendedwarning">
  <img src="/images/warning.png" style="padding-right: 10px;" />
  <b>${htmlEscape(I18n.t('#application.warnings.titles.suspended', 'Institution Suspended'))}</b>
  <div style="float: right; padding-right: 25px;">${htmlEscape(
    I18n.t(
      '#application.warnings.details.suspended',
      "This institution's Canvas account has been suspended. Only Account Administrators may log in to correct any problems."
    )
  )}</div>
</div>
`

$('#suspendedwarning').click(function () {
  return $(this).hide()
})
